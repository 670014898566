import React from "react"
import { Link } from "gatsby"

const Layout = props => {
  const { title, children, location = {} } = props
  const [toggleNav, setToggleNav] = React.useState(false)

  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <MainLink to={"/"} location={location}>home</MainLink>
              <MainLink to={`/about`} location={location}>about</MainLink>
              <MainLink to={`/tattoos`} location={location}>tattoos</MainLink>
              <MainLink to={`/designs`} location={location}>designs</MainLink>
              <MainLink to={`/other`} location={location}>other</MainLink>
              <MainLink to={`/contact`} location={location}>contact</MainLink>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`} onClick={()=>setToggleNav(false)}>
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://www.facebook.com/kataaza.ttt/"
                title="facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://www.instagram.com/kataaza.ttt/"
                title="instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
      </footer>
    </div>
  )
}

const MainLink = ({ to, location, children }) => {
  return <li className={location.pathname === to ? "nav-current" : undefined} role="menuitem">
    <Link to={to}>{children}</Link>
  </li>
}

export default Layout
